<template>
  <div>
    <div v-if="!edit">
      <v-btn class="pa-0 black--text" v-if="boxOffice && boxOffice.mailchimpAccountName" href="https://login.mailchimp.com/" target="_blank" color="#f7d938">
        <v-img class="mr-3" src="/mailchimp.png" height="25px" width="24px"/>
        <span>Already Connected</span>
      </v-btn>
      <span v-else>Not connected</span>
    </div>
    <div v-else>
      <div v-if="boxOffice && boxOffice.mailchimpAccountName">
        <v-label v-if="label">You have connected your mailchimp account: {{boxOffice.mailchimpAccountName}} (list {{boxOffice.mailchimpListName}}) 
          and can send emails to event attendees.
          <tooltip top>
            Everyone who buys a ticket will be added to your mailchimp<br/> so that you can easily stay in touch with them.
          </tooltip>
        </v-label>
        <div>
          <v-btn color="error" class="pa-0" @click="disconnectMailchimp">
            <v-img class="mr-3 mt-n1" src="/mailchimp.png" height="25px" width="24px"/>Disconnect Mailchimp
          </v-btn>
        </div>
      </div>
      <div v-else>
        <v-label v-if="label">To send mail to event attendees, you must connect to mailchimp.
          <tooltip top>
              <p>When customers buy tickets, Happy tickets will send them out on email.</p>
              <p>You may like to send customers addition emails with details and reminders <br/>
              about your events, or promotions for other events.</p>
              <p>To make it easy for you to send these additonal emails, we can put every customer <br/>
              you get into the world's favourite mailing list management software (Mailchimp).<br/>
              So that you can easily send them targeted emails, each customer is tagged with the <br/>
              event and ticket they brought, and if they "opt in" to additional marketing.</p>
              <p>You don't have to connect or create a Mailchimp account, so if you are not sure<br/>
              right now, you can always do this later.</p>
          </tooltip>
        </v-label>
        <div>
          <v-btn color="#f7d938" class="pa-0 black--text" @click="connectMailchimp">
            <v-img class="mr-3 mt-n1" src="/mailchimp.png" height="25px" width="24px"/>Connect to Mailchimp
          </v-btn>
        </div>
      </div>
    </div>

    <v-dialog v-model="showMailchimpToggle" persistent max-width="600px">
      <v-card>
        <v-card-title>Mailchimp is Connected             
          <v-spacer></v-spacer>
          <v-icon large @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            Congratuations, you have connected to Mailchimp.  All new customers will be added Mailchimp so you can email them later.
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="close">Close</v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js';
  import Store from '@/services/Store.js';

  export default {

    components: {
      Tooltip: () => import('./components/Tooltip'),
    },  
    
    computed: {
      utils: function () {
        return Utils;
      }
    },

    data () {
      return {
        showMailchimpToggle: false,
        boxOffice: null,
      }
    },

    props: {
      value: null,
      edit: {
        type: Boolean,
        default: false,
      },
      label: {
        type: Boolean,
        default: false,
      },
    },

    watch: {
      value: function () {
        this.boxOffice = this.value;
      }
    },

    created() {
      this.boxOffice = this.value;
      let uri = window.location.search.substring(1); 
      let params = new URLSearchParams(uri);
      let code = params.get("code");
      let mailchimpCallback = params.get("mailchimpCallback");
      if (mailchimpCallback && code) {
        var id = this.$route.params.id;
        this.connectedMailchimp(id, code)
        this.showMailchimpToggle = true; 
      }
    },
    
    methods: {

      connectMailchimp() {
        this.$emit('clicked');
        var url = "https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=";
        url += process.env.VUE_APP_MAILCHIMP_CLIENT_ID;
        url += "&redirect_uri=" + this.makeMailchimpRedirect(this.boxOffice.id);
        window.location = url;
      },

      async connectedMailchimp(id, code) {
        var payload = {}
        payload.id = id;
        payload.code = code;
        payload.redirectUrl = this.makeMailchimpRedirect(id);
        this.boxOffice = await Api.post(this, "BoxOffice", "connectMailchimp", payload);
        Store.boxOffice.name = this.boxOffice.name;
        Store.boxOffice.id = this.boxOffice.id;
      },

      async disconnectMailchimp() {
        this.boxOffice = await Api.post(this, "BoxOffice", "disconnectMailchimp", this.boxOffice);
        this.$emit('input', this.boxOffice)
      },
      
      makeMailchimpRedirect(id) {
        return this.makeThisUrl(id) + "?mailchimpCallback=true";
      },
      
      makeThisUrl(id) {
        var redirect = window.location.origin;
        redirect = redirect.replace("localhost", "127.0.0.1");
        var pathname = window.location.pathname;
        if (!pathname.endsWith(id)) {
          pathname += "/" + id;
        }
        redirect += pathname;
        return redirect;
      },

      close() {
        this.showMailchimpToggle = false; 
        this.$emit('connected', this.boxOffice);
        var redirect = window.location.pathname;
        redirect = redirect.replace("?.*", "");
        this.$router.push(redirect);
      },
    },
  }

</script>
